import React from 'react'

export const handleClick = (url) => {
    if (typeof window !== "undefined") {
        window.gtag('event', 'Ligar', {
            event_category: 'Telefone - Geral',
            event_label: '234397700'
        })
        window.gtag('event', 'chamadas', {
            tipo_chamada: 'Fixo',
        })
        window.open(url, '_self', 'noreferrer')
    }
}