export const theme = {
  spacingXXXS: '.5rem',
  spacingXXS: '1rem',
  spacingXS: '1.5rem',
  spacingS: '3rem',
  spacingM: '6rem',
  spacingL: '9rem',
  spacingXL: '15rem',
  fontFamily: '"PT Sans", sans-serif',
  fontRegular: 400,
  fontBold: 700,
  darkBackground: '#000000',
  lightBackground: '#f0f0f0',
  lightTitle: '#ffffff',
  lightText: '#D5D4D4',
  darkTitle: '#80807f',
  darkSubTitle: '#464545',
  darkText: '#262626',
  introTitle: '#b4b4b4',
  grey: '#a1a1a1',
  lightGrey: '#dedede',
}
